import { createGlobalStyle } from "styled-components"
// import mainFont // add local font if you want
import fontLight from "../../assets/fonts/Montserrat-Light.ttf"
import fontRegular from "../../assets/fonts/SourceSansPro-Regular.ttf"

export const GlobalStyle = createGlobalStyle`
src/assets/fonts/Montserrat-Light.ttf
*,*::before,*::after{
    margin: 0;
    padding: 0;
    ${"" /* outline: 1px solid red !important; */}
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    font-family: "Montserrat";
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: local('Montserrat Light'), url(${fontLight}) format("truetype") ;
    font-display:swap;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat', sans-serif;
    src: local('Montserrat Regular'), url(${fontRegular}) format("truetype") ;
    font-display:swap;
    font-style: normal;
}

body{
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
}

:root{
    // Fonts As per the type scale generator => https://material-io.cn/inline-tools/typography/
 --fontBig: 5em; //88 px
 --fontxxxl: 4.5em; //88 px
 --fontxxl: 3.4375em; //55 px
 --fontxl: 2.75em; //44 px
 --fontlg: 1.9375em; //31 px
 --fontmd: 1.375em; //22 px
 --fontsm: 1.125em; //18 px
 --fontxs: 1em; //16 px
 --fontxxs: 0.75em; //12 px

 


  // Colors
  --dark: #000000;
  --grey: #484848;
  --greyLight: #979797;
  --offWhite: #eeeeee;
  --white: #ffffff;
  --blue: #0071e3;
  --blueRgba:"0, 113, 227";
// From Necta branding page https://surgeconsultingnz.sharepoint.com/sites/NectaNz/SitePages/Necta-Branding.aspx
    // "#EDDD0F", "Necta Yellow 1", "237, 221, 15"
    --nectaYellow1: #EDDD0F;
    // "#DED409", "Necta Yellow 2", "222, 212, 9"
    --nectaYellow2: #DED409;
    // "#9E930A", "Necta Greenish", "158, 147, 10"
    --nectaGreenish: #9E930A;
    // "#C4C4C4", "Necta Grey", "196, 196, 196"
    --nectaGrey: #C4C4C4;
    // "#000000", "Necta Black", "0, 0, 0, 1"
    --nectaBlack: #000000;

  //fonts
  --fontL: "Montserrat Light";
  --fontR: "Montserrat Regular";
  --fontB: "Montserrat";
  
  // gradient
  --gradient: #BF42E8 0%, #E86C42 20%, #E86C42 39%, #DED409 76%, #EDDD0F 100%;
}


`
