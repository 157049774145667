import React from 'react';
import { ApolloProvider } from "@apollo/client";
import "@fontsource/montserrat";
import "@fontsource/montserrat/500.css";
import { client } from "./lib/apolloClient";
import "./src/assets/css/bootstrap.min.css";
import "./src/assets/scss/now-ui-kit.scss?v=1.4.0";
import "./src/assets/demo/demo.css?v=1.4.0";
import "./src/assets/demo/react-demo.css?v=1.4.0";
import "./src/assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "react-datepicker/dist/react-datepicker.css";
import './src/assets/scss/index.scss';
import { GlobalStyle } from "./src/Landing/styles/GlobalStyle";
import axios from 'axios';

export const onClientEntry = () => {
  // IntersectionObserver Polyfill
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
    console.log('# IntersectionObserver is polyfilled!');
  }

  // Fetch user location data
  axios.get('https://ipapi.co/json/')
    .then(response => {
      const countryCode = response.data.country;
      const region = response.data.region;
      window.globalCountryCode = countryCode;
      window.globalRegion = region;
    })
    .catch(error => {
      console.error('Error fetching country code:', error);
    });

  // Apollo tracking script
  const initApollo = () => {
    var n = Math.random().toString(36).substring(7);
    var o = document.createElement("script");
    o.src = "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" + n;
    o.async = true;
    o.defer = true;
    o.onload = function () {
      window.trackingFunctions.onLoad({ appId: "66cc315d1c84d3019ad8e12b" });
    };
    document.head.appendChild(o);
  };

  initApollo(); // Call the Apollo tracking script function
};

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalStyle />
    <ApolloProvider client={client}>
      {element}
    </ApolloProvider>
  </>
);

export const onRouteUpdate = ({ location }) => {
  if (location.pathname === '/listings') {
    window.location.reload();
  }
};
